<template>
  <q-list class="userDropdown">
    <template v-for="(element, index) in dropDownElements">
      <q-item v-if="!element.isDisabled" :key="index" clickable class="userDropdown__box">
        <q-item-section class="userDropdown__item" @click="handleAction(element.action)">
          <q-icon :name="`o_${element.icon}`" class="userDropdown__icon" />
          {{ formatItemName(element.name) }}
        </q-item-section>

        <BaseTooltip v-if="element.isTooltipEnabled === true" :tooltip="tooltip" />
      </q-item>
    </template>
  </q-list>
</template>

<style lang="scss" scoped>
.userDropdown {
  @include inter-font($size: 14px, $bolded: true, $color: var(--main-text-color));
  background: #fff;
  border: 1px solid rgba(151, 151, 151, 0.2);
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  min-width: 200px;

  &__box {
    transition: background-color 0.2s ease;
    border-radius: 6px;
    margin: 0 4px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
      font-weight: inherit;
      color: inherit;
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.08);
      font-weight: inherit;
      color: inherit;
    }
  }

  &__item {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    align-items: center !important;
    padding: 12px 16px;
  }

  &__icon {
    font-size: 20px;
    margin-right: 12px;
    color: rgba(0, 0, 0, 0.7);
  }

  .q-item {
    min-height: auto;
    padding: 0;

    &:first-child {
      margin-top: 4px;
    }

    &:last-child {
      margin-bottom: 4px;
    }

    &:hover {
      font-weight: inherit !important;
      color: inherit !important;
    }
  }
}
</style>

<script>
// Vuex
import { mapGetters } from 'vuex';

// Base Components
import BaseTooltip from '@base/BaseTooltip.vue';

/**
 * Component used to handle the user dropdown content
 *
 * @displayName UserDropdown
 */
export default {
  name: 'UserDropdown',
  components: {
    BaseTooltip
  },
  data() {
    return {
      tooltip: this.$t('COMING_SOON')
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isEditor: 'user/isEditor',
      isPhotographer: 'user/isPhotographer',
      isSubClient: 'user/isSubClient',
      user: 'user/getUser'
    }),
    dropDownElements() {
      return [
        {
          name: this.$t('ACCOUNT_INFORMATION'),
          action: 'ACCOUNT_INFO',
          isDisabled: this.isAdmin || this.isEditor || this.isSubClient,
          icon: 'person'
        },
        {
          name: 'Notifications',
          action: 'SHOW_NOTIFICATIONS_SETTINGS',
          isDisabled: !this.isAdmin,
          icon: 'settings'
        },
        // {
        //   name: 'Integrations',
        //   action: 'SHOW_INTEGRATIONS',
        //   isDisabled: this.isEditor || this.isPhotographer,
        //   icon: 'hub'
        // },
        {
          name: 'AI Assistant',
          action: 'GENERATE_QUOTATIONS',
          isDisabled: !this.isAdmin,
          icon: 'sparkles'
        },
        {
          name: this.$t('PAYMENT_DETAILS'),
          action: 'PAYMENT_DETAILS',
          isDisabled: !this.isPhotographer,
          icon: 'credit_card'
        },
        {
          name: 'Manage Team',
          action: 'MANAGE_TEAM',
          isDisabled: this.isEditor || this.isSubClient || this.isPhotographer || this.isAdmin,
          icon: 'group_add'
        },
        {
          icon: 'logout',
          name: 'Logout',
          action: 'LOGOUT'
        }
      ];
    }
  },
  methods: {
    /**
     * Method used to perform a specific action based on the link clicked from the dropdown
     */
    handleAction(action) {
      const actions = {
        ACCOUNT_INFO: () => this.$router.push({ name: 'account' }),
        LOGOUT: () => this.handleLogout(),
        PAYMENT_DETAILS: () => this.$router.push({ name: 'paymentDetails' }),
        SHOW_NOTIFICATIONS_SETTINGS: () => this.$router.push({ name: 'notificationsSettings' }),
        GENERATE_QUOTATIONS: () => this.$router.push({ name: 'quotes' }),
        SHOW_INTEGRATIONS: () => this.$router.push({ name: 'integrations' }),
        MANAGE_TEAM: () => this.$router.push({ name: 'subAccounts' })
      };

      actions[action]();
    },
    /**
     * Method used to format the item name
     */
    formatItemName(name) {
      return name
        .split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
    }
  }
};
</script>
