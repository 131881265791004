import CustomerIOService from '@/api/modules/customerio';

export default {
  /**
   * Install the Customer.io plugin
   * @param {Object} Vue - Vue constructor
   * @param {Object} options - Plugin options
   * @param {string} options.siteId - Customer.io Site ID
   */
  install(Vue, options = {}) {
    const { siteId } = options;

    if (!siteId) {
      console.error('[CustomerIO Plugin] Site ID is required');
      return;
    }
    // Initialize the Customer.io service
    CustomerIOService.init(siteId);

    // Make Customer.io service available globally in all components
    Vue.prototype.$customerio = CustomerIOService;
  }
};
