<template>
  <BaseDropdown v-model="isDropdownOpen">
    <template #trigger>
      <div class="user-button-container">
        <div class="user-info-wrapper">
          <div v-if="loading" class="user-avatar skeleton"></div>
          <div v-else class="user-avatar">
            {{ userInitial }}
          </div>
          <div class="user-info">
            <div v-if="loading" class="user-name skeleton"></div>
            <div v-else class="user-name" :title="user">{{ truncatedName }}</div>
            <div v-if="loading" class="user-role skeleton"></div>
            <div v-else class="user-role">{{ userRole }}</div>
          </div>
        </div>
        <q-icon :name="isDropdownOpen ? 'arrow_drop_up' : 'arrow_drop_down'" size="24px" class="dropdown-arrow" />
      </div>
    </template>
    <template #menu>
      <UserDropdown />
    </template>
  </BaseDropdown>
</template>

<script>
// Base component
import BaseDropdown from '@base/BaseDropdown.vue';

// Components
import UserDropdown from '@/components/UserDropdown.vue';

// Vuex
import { mapGetters } from 'vuex';

export default {
  name: 'UserButton',
  components: {
    BaseDropdown,
    UserDropdown
  },
  data() {
    return {
      isDropdownOpen: false,
      maxNameLength: 15,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient',
      isSubClient: 'user/isSubClient',
      isPhotographer: 'user/isPhotographer'
    }),
    user() {
      return this.$store.getters['user/getUser'].name;
    },
    userInitial() {
      return this.user.charAt(0).toUpperCase();
    },
    truncatedName() {
      if (this.user.length > this.maxNameLength) {
        return `${this.user.substring(0, this.maxNameLength)}...`;
      }
      return this.user;
    },
    userRole() {
      if (this.isAdmin) return 'Admin';
      if (this.isClient) return 'Client';
      if (this.isSubClient) return 'Sub-Client';
      return 'Photographer';
    }
  }
};
</script>

<style lang="scss" scoped>
.user-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 14px;
  border: 1px solid var(--terziary-btn-color);
  border-radius: 8px;
  cursor: pointer;
  background-color: transparent;
  gap: 8px;
  transition: background-color 0.3s ease;
  max-width: 220px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.user-info-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
}

.user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--main-btn-color);
  color: white;
  font-weight: bold;
  flex-shrink: 0;
  @include mona-sans-font($size: 14px, $bolded: true, $color: var(--main-text-color));

  &.skeleton {
    background-color: #e0e0e0;
    animation: pulse 1.5s infinite ease-in-out;
  }
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
  overflow: hidden;
}

.user-name {
  @include mona-sans-font($size: 14px, $bolded: true, $color: var(--main-text-color));
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  &.skeleton {
    height: 14px;
    width: 80%;
    background-color: #e0e0e0;
    border-radius: 4px;
    animation: pulse 1.5s infinite ease-in-out;
  }
}

.user-role {
  @include inter-font($size: 12px, $bolded: false, $color: var(--main-text-color));

  &.skeleton {
    height: 12px;
    width: 60%;
    background-color: #e0e0e0;
    border-radius: 4px;
    margin-top: 4px;
    animation: pulse 1.5s infinite ease-in-out;
  }
}

.dropdown-arrow {
  color: var(--main-text-color);
  margin-left: 8px;
  transition: transform 0.3s ease;
  flex-shrink: 0;
}

@keyframes pulse {
  0% {
    opacity: 0.6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.6;
  }
}
</style>
