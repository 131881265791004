<template>
  <div
    v-if="
      isUserLoggedIn ||
      $route.name === 'phRegistration' ||
      $route.name === 'flashyShoots' ||
      $route.name === 'businessRegistration' ||
      $route.name === 'stripeSuccess' ||
      $route.name === 'stripeError' ||
      $route.name === 'authLogin' ||
      $route.name === 'authRegister' ||
      $route.name === 'auth0Callback'
    "
    id="app"
  >
    <!-- Account Verification Banner - Clean implementation -->
    <AccountVerificationBanner v-if="shouldShowVerificationBanner && bannerVisible" :userEmail="userEmailForBanner" @dismiss="temporarilyDismissBanner" />

    <PageLayout :sections="sections" :class="{ 'has-verification-banner': shouldShowVerificationBanner && bannerVisible }">
      <template #navbar>
        <NewNavbar v-if="isUserLoggedIn" />
      </template>
      <template #body>
        <q-page-container class="app__body">
          <router-view />
        </q-page-container>
      </template>
    </PageLayout>
    <GlobalModal v-if="shouldShowModal" />
  </div>
</template>

<script>
// Api
import { SettingsApi } from '@api/index';
import { UserApi } from '@api/modules/user';

// Vuex
import { mapGetters } from 'vuex';

// Components
import PageLayout from '@components/layout/PageLayout.vue';

// Common components
import NewNavbar from '@common/NewNavbar.vue';
import AccountVerificationBanner from '@components/common/AccountVerificationBanner.vue';

import { AuthApi } from '@api/index';
import GlobalModal from '@components/common/GlobalModalFields.vue';

export default {
  name: 'App',
  components: {
    GlobalModal,
    PageLayout,
    NewNavbar,
    AccountVerificationBanner
  },
  data() {
    return {
      sections: [
        {
          name: 'navbar'
        },
        {
          name: 'body'
        }
      ],
      userDetails: null,
      isLoadingUserDetails: false,
      bannerVisible: true, // New state to control banner visibility
      bannerDismissTimerId: null, // To handle the timeout for temporary dismissal
      verificationStatusChecked: false
    };
  },
  computed: {
    ...mapGetters({
      error: 'error/getError',
      notification: 'notification/getNotification',
      isAdmin: 'user/isAdmin',
      isClient: 'user/isClient',
      isEditor: 'user/isEditor',
      isPhotographer: 'user/isPhotographer',
      isUserLoggedIn: 'user/isUserLoggedIn',
      user: 'user/getUser'
    }),

    shouldShowModal() {
      const isAuthPage = ['authLogin', 'authRegister', 'auth0Callback'].includes(this.$route.name);
      if (isAuthPage) return false;

      const isClient = this.$store.getters['user/isClient'];

      if (!isClient) {
        return false;
      }

      // If we have detailed user info, use that to determine verification status
      if (this.userDetails) {
        return this.isUserLoggedIn && !this.userDetails.has_company_name;
      }

      // Fallback to store user if detailed info not yet loaded
      if (this.user) {
        return this.isUserLoggedIn && !this.user.has_company_name;
      }

      return false;
    },
    // Determine if we should show the verification banner
    shouldShowVerificationBanner() {
      const isAuthPage = ['authLogin', 'authRegister', 'auth0Callback'].includes(this.$route.name);

      // Don't show on auth pages or when not logged in
      if (isAuthPage || !this.isUserLoggedIn || !this.isClient) return false;

      // Only check verification status if we've verified with API
      if (!this.verificationStatusChecked) return false;

      // Use userDetails as the source of truth (comes from API)
      return this.userDetails && this.userDetails.account_verified === false;
    },

    // Computed property to get the correct email to display
    userEmailForBanner() {
      return (this.userDetails && this.userDetails.email) || (this.user && this.user.email) || '';
    }
  },
  watch: {
    error() {
      this.$q.notify({ color: 'negative', message: this.error.message || this.$t(`ENDPOINT.ERROR.${this.error.endpoint}`) });
    },
    notification() {
      this.$q.notify(this.notification);
    },
    // Watch for user changes to fetch detailed info
    user: {
      immediate: true,
      handler(newUser) {
        if (newUser && newUser.id) {
          this.fetchUserDetails(newUser.id, newUser.role);
        } else {
          this.userDetails = null;
        }
      }
    },
    // Add a new watcher for userDetails
    userDetails: {
      handler(newDetails) {
        // Only proceed if we're logged in and have loaded details
        if (this.isUserLoggedIn && newDetails) {
          // Check if company_name is missing
          if (!newDetails.has_company_name) {
            const temporalFullname = newDetails.name || '';
            this.showCompanyNameModal(temporalFullname);
          }
        }
      },
      deep: true
    },
    // Add a new watcher for route changes to restore banner visibility
    $route() {
      // If we've temporarily dismissed the banner but we navigate to a new page,
      // we should show it again if needed
      this.resetBannerVisibility();
    },

    // Add a watcher for shouldShowVerificationBanner
    shouldShowVerificationBanner(newVal) {
      if (newVal) {
        // Reset banner visibility whenever the verification status changes
        this.resetBannerVisibility();
      }
    }
  },
  methods: {
    showCompanyNameModal(placeholder = '') {
      this.$globalModal.open({
        title: 'Complete your profile',
        data: {
          companyName: placeholder
        },
        onSubmit: async data => {
          // Do something with the company name
          await this.completeProfile(data.companyName);
        }
      });
    },
    async handleEmailVerificationParams() {
      // Get parameters from URL
      const params = AuthApi.getVerificationParamsFromUrl();

      // Check if this is a verification URL
      if (params && params.code === 'success') {
        try {
          // Process verification code if it exists
          if (params.success) {
            try {
              await AuthApi.verifyAccount();

              // Update local user state immediately
              if (this.userDetails) {
                this.userDetails.account_verified = true;
              }

              // Show notification
              this.$q.notify({
                color: 'positive',
                message: 'Account verified successfully!'
              });
            } catch (error) {
              // Show notification
              this.$q.notify({
                color: 'warning',
                message: 'Account verification failed. Please try again.'
              });
            }
          }

          // Clean up URL
          AuthApi.cleanUrl();
        } catch (error) {
          console.error('Error during verification process:', error);
          this.$q.notify({
            color: 'negative',
            message: 'An error occurred during account verification.'
          });

          AuthApi.cleanUrl();
        }
      }
    },
    /**
     * Fetches services from the API
     */
    async completeProfile(companyName) {
      const profile = {
        company_name: companyName,
        contact_name: this.userDetails.name,
        contact_email: this.userDetails.email,
        contact_phone: this.userDetails.phone,
        industry: this.$t('INDUSTRY_LIST.OTHER'),
        role: this.userDetails.role
      };

      await UserApi.updateUser({ id: this.userDetails.id, payload: profile });
    },

    /**
     * Fetch detailed user information from the API
     * @param {string} id - User ID
     * @param {string} role - User role
     */
    async fetchUserDetails(id, role) {
      if (!id || !role || this.isLoadingUserDetails) return;

      this.isLoadingUserDetails = true;
      this.verificationStatusChecked = false;

      try {
        const response = await UserApi.getUser({ id, role });

        if (response && response.data && response.data.user) {
          this.userDetails = response.data.user;
          this.verificationStatusChecked = true;
        }
      } catch (error) {
        // Handle error silently
      } finally {
        this.isLoadingUserDetails = false;
      }
    },

    // Add new methods for banner visibility management
    temporarilyDismissBanner() {
      this.bannerVisible = false;

      // Clear any existing timer
      if (this.bannerDismissTimerId) {
        clearTimeout(this.bannerDismissTimerId);
      }

      // Set a timer to show the banner again after 2 hours
      // This ensures users aren't permanently dismissing it
      this.bannerDismissTimerId = setTimeout(() => {
        this.resetBannerVisibility();
      }, 2 * 60 * 60 * 1000); // 2 hours
    },

    resetBannerVisibility() {
      this.bannerVisible = true;
    },

    // Clean up method to be called on beforeDestroy
    cleanupBannerTimer() {
      if (this.bannerDismissTimerId) {
        clearTimeout(this.bannerDismissTimerId);
      }
    }
  },
  async created() {
    if (this.isUserLoggedIn) {
      try {
        const [services, settings] = await Promise.all([this.getServices(), SettingsApi.settings()]);

        if (settings && settings.data) {
          this.$store.dispatch('services/updateServices', services);
          this.$store.dispatch('settings/updateSettings', settings.data);
        }
      } catch (error) {
        // Error handling can be improved if needed
      }
    }
  },
  async mounted() {
    if (this.isUserLoggedIn) {
      this.handleEmailVerificationParams();
    }
  },
  beforeDestroy() {
    this.cleanupBannerTimer();
  }
};
</script>

<style lang="scss">
* {
  font-synthesis: none !important;
}

#app {
  font-family: $inter-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh;
  min-height: 100vh;
  background-color: var(--main-bg-color);
  overflow: hidden;
  position: relative;
}

.app {
  &__body {
    height: 100vh;
  }
}

/* Updated layout adjustments for the taller banner */
.verification-banner + .page-layout,
.verification-banner ~ .page-layout {
  margin-top: 64px; /* Increased to match the larger banner height */
}

/* Ensure the PageLayout is positioned correctly */
.page-layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  position: relative;
  transition: margin-top 0.3s ease, padding-top 0.3s ease;

  &.has-verification-banner {
    padding-top: 64px; /* Add padding when banner is visible */
  }
}

/* Style for navbar when banner is present */
.verification-banner ~ div .q-layout,
.verification-banner + div .q-layout {
  padding-top: 64px; /* Increased to match the banner height */
}

.ml-auto {
  margin-left: auto;
}

.w-100 {
  width: 100%;
}

/* Additional styles to ensure navbar positioning is correct with banner */
.has-verification-banner .page-layout__navbar {
  position: relative;
  top: 64px;
}

/* Adjust all content when banner is visible */
.verification-banner ~ div .q-layout {
  padding-top: 64px;
}
</style>
