<template>
  <q-layout view="hHh lpr lfr" class="pageLayout">
    <template v-for="(section, index) in sections">
      <section v-if="$slots[section.name]" :key="index" :style="section.style">
        <slot :name="section.name"></slot>
      </section>
    </template>
  </q-layout>
</template>

<script>
/**
 * Component used to build the page layout.
 *
 * @displayName PageLayout
 */

export default {
  name: 'PageLayout',

  props: {
    /**
     * Array of horizontal sections of the page with defined property
     */
    sections: {
      default: () => [],
      type: Array,
      required: true,
      /**
       * The validator checks if every section has the properties 'name'
       */
      validator: value => {
        const mandatory_keys = ['name'];
        let is_valid = true;
        for (const section of value) {
          const keys = Object.keys(section);
          is_valid = mandatory_keys.every(key => keys.includes(key));

          if (!is_valid) return;
        }

        return is_valid;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.pageLayout {
  display: flex;
  flex-direction: column;
  height: 100%;

  > section {
    max-height: 100%;
    width: 100%;
    height: 100%;
  }
}
</style>
