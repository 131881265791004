import JSZip from 'jszip';

import moment from 'moment';
import { ServiceApi } from '@api/index';

import store from '../store/index';

// Store
import { mapGetters } from 'vuex';

// Utils
import { setCookie } from '@utils/cookies';

const getTime = isoString => {
  const dateIndex = isoString.indexOf('T') + 1;
  const timeIndex = isoString.indexOf('Z');
  const time = isoString.slice(dateIndex, timeIndex).slice(0, 5);

  return moment(time, ['HH:mm']).format('hh:mm A');
};

const helpers = {
  computed: {
    ...mapGetters({
      services: 'services/getServices'
    })
  },
  methods: {
    /**
     * Returning min and max from array of numbers
     */
    arrayMinMax(array) {
      const mapToNumbers = array.map(value => Number(value));

      return {
        max: Math.max(...mapToNumbers),
        min: Math.min(...mapToNumbers)
      };
    },
    fromDateTimeToDateAndTimeRange({ duration = 0, from = '', to = '' }) {
      if (from === '' || from === null) {
        return '';
      } else {
        const [date] = from.split('T');
        const [year, month, day] = date.split('-');
        const formattedDate = `${day}/${month}/${year}`;
        const calendarFormattedDate = `${year}-${month}-${day}`;
        let calendarEndFormattedDate = `${year}-${month}-${day}`;

        const fromTime = getTime(from);
        let toTime;

        if (to !== '') {
          toTime = getTime(to);
        }

        if (duration >= 0) {
          const startTime = moment(fromTime, 'hh:mm a');
          toTime = startTime.add(duration, 'minutes').format('hh:mm A');

          const hours = duration / 60;

          if (hours > 24) {
            const daysToAdd = Math.floor(hours / 24);
            calendarEndFormattedDate = `${year}-${month}-${Number(day) + daysToAdd}`;
          }
        }

        const formattedFromTime = moment(fromTime, 'hh:mm a').format('hh:mm A');
        const timeRange = `${fromTime} - ${toTime}`;
        const startDateTime = `${calendarFormattedDate} ${formattedFromTime}`;
        const endDateTime = `${calendarEndFormattedDate} ${toTime}`;

        return {
          date: formattedDate,
          timeStart: startDateTime,
          time: timeRange,
          timeEnd: endDateTime,
          fromTime,
          toTime
        };
      }
    },
    /**
     * use for check between two dates and time
     * get the current date and time, the start time  and the end time of the pkg, all of this with the same format.
     * compare the date with moment and and save the result in a variable
     */
    checkDateTimeBetween({ duration = 0, from = '', to = '', daysMissing }) {
      const todayDateTime = moment().format('YYYY MM DD hh:mm');
      const todayDate = moment().format('YYYY MM DD');
      const { date, fromTime, toTime } = this.fromDateTimeToDateAndTimeRange({ duration, from, to });

      const currentdate = moment(date).format('YYYY MM DD');

      const formattedFromTime = moment(fromTime).format('hh:mm');

      const startDateTime = `${currentdate} ${formattedFromTime}`;
      const endDateTime = `${currentdate} ${toTime}`;

      const onGoing = moment(todayDateTime).isBetween(startDateTime, endDateTime, undefined, '[]') || moment(todayDateTime).isSame(startDateTime) || moment(todayDateTime).isSame(endDateTime);

      let daysCount = [];
      daysMissing.forEach(date => {
        const compareDate = moment(todayDate).isSame(date);
        if (compareDate) daysCount.push(date);
      });
      return {
        onGoing,
        daysCount
      };
    },
    downloadFile({ file, isBlob = false, name }) {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.id = 'download';
      a.style = 'display: none';

      if (!isBlob) file = new Blob([file], { type: 'octet/stream' });

      const url = window.URL.createObjectURL(file);
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
    },
    /**
     * Retrieving package image based on its id
     */
    getPackageImage({ idPackage, idAsset }) {
      const url = `${store.getters['settings/getSettings'].pictures_static_url}/package`;
      return `${url}/${idPackage}/${idAsset}`;
    },
    /**
     * Retrieving service image based on its id
     */
    getServiceImage(serviceId = 4) {
      const url = `${store.getters['settings/getSettings'].pictures_static_url}/service`;
      return `${url}/${serviceId}/1`;
    },
    async getServices(payload) {
      const _payload = payload || {
        sorting: {
          field: 'id',
          direction: 'desc'
        }
      };
      const services = await ServiceApi.search(_payload);

      if (services.statusCode === 200) return services.data.services;

      return [];
    },
    /**
     * Requiring from assets the media requested
     */
    requireMedia(url) {
      if (!url) return;
      let media = require(`@/assets${url}`);

      return media;
    },
    convertDateTime({ datetime = '', duration = 0 }) {
      const [date] = datetime.split('T');
      const [year, month, day] = date.split('-');

      const formattedDate = `${day}/${month}/${year}`;

      const time = getTime(datetime);

      // Adding one hour
      const new_date = new Date(datetime);
      const durationHours = duration / 60;
      new_date.setHours(new_date.getHours() + durationHours);
      const endTimeIso = new_date.toISOString();

      const endTime = getTime(endTimeIso);

      const timeRange = `${time} - ${endTime}`;

      return {
        date: formattedDate,
        time: timeRange,
        endingDate: new_date
      };
    },
    /**
     * Use this function to convert an iso string to dd/mm/yyyy format
     * @param {String} date - date in iso string format
     */
    formatDate(isostring) {
      const date = isostring.substring(0, 10);
      const [year, month, day] = date.split('-');

      return `${day}-${month}-${year}`;
    },
    /**
     * Getting css variable to be used in javascript
     */
    getCssVariable(variable) {
      // eslint-disable-next-line quotes
      const light = "[data-theme='light']";
      // eslint-disable-next-line quotes
      const dark = "[data-theme='dark']";

      const el = document.querySelector(light) || document.querySelector(dark);
      const style = getComputedStyle(el);

      return style.getPropertyValue(variable);
    },
    /**
     *  set the current date
     *
     */
    getCurrentDate() {
      const current = new Date();
      const currentDate = `${current.getFullYear()}/${current.getMonth() + 1}/${current.getDate()}`;
      const firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
      const lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);
      return {
        currentDate,
        firstDay,
        lastDay
      };
    },
    /**
     * Returning service name based on id
     * @param {Number} id - The id of the service we want to retrive the name
     * @returns {String} Returns the service name
     */
    getServiceName({ id }) {
      const service = this.services.find(service => service.id === id) || {};
      return service.name;
    },
    /**
     * Removing the login token and redirecting to login page
     */
    async handleLogout() {
      this.$store.dispatch('user/resetState');
      await setCookie({ cookieName: 'jwt', cookieValue: '', expiration: 'Thu, 01 Jan 1970 00:00:00 GMT' });
      window.location.replace(`${process.env.VUE_APP_FE_DOMAIN}/auth/login`);
    },
    /**
     * Method used to open whatsapp
     */
    openWhatsapp() {
      window.open('https://api.whatsapp.com/send?phone=97143294175', '_blank');
    },
    /*
     * Set the order of items of array by the last update to the first.
     */
    orderLastFirst(items) {
      items.sort(function (a, b) {
        var updateA = a.last_update;
        var updateB = b.last_update;
        if (updateA < updateB) return 1;
        if (updateA > updateB) return -1;
        return 0;
      });
    },
    /*
     * Set the order of items of array from the one with fewer images to the one with more images.
     * Set the order of items of array by the is_plus to the not plus.
     */
    orderByPictureNumberAndPlus(items) {
      items.sort((a, b) => a.picture_number - b.picture_number) && items.sort((a, b) => b.is_plus - a.is_plus);
    },
    setPhotographerAging(date) {
      const current = new Date();
      const photographerAge = date < `${current.getFullYear() - 21}/${current.getMonth() + 1}/${current.getDate()}`;

      return photographerAge;
    },
    setCalendarShootDate(date) {
      const shootDate = date >= this.getCurrentDate().currentDate;
      return shootDate;
    },
    setCalendarBirthDate(date) {
      const birthDate = date <= this.getCurrentDate().currentDate;
      return birthDate;
    },
    /**
     * Updating array by removing or adding the passed element
     */
    updateArray({ array, element, param = '' }) {
      const elementInArray = array.find(el => {
        if (param) return el[param] === element[param];
        return el === element;
      });

      if (elementInArray) {
        return array.filter(el => {
          if (param) return el[param] !== element[param];
          return el !== element;
        });
      }

      array.push(element);
      return array;
    },
    /**
     * Given an aray of files, returns a blob zipped one
     */
    async zipFiles({ files }) {
      const zip = new JSZip();
      for (const file of files) {
        zip.file(file.name, file, { base64: true });
      }
      const blob = await zip.generateAsync({ type: 'blob' });
      return blob;
    }
  }
};

export default helpers;
