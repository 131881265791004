var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.isUserLoggedIn ||
    _vm.$route.name === 'phRegistration' ||
    _vm.$route.name === 'flashyShoots' ||
    _vm.$route.name === 'businessRegistration' ||
    _vm.$route.name === 'stripeSuccess' ||
    _vm.$route.name === 'stripeError' ||
    _vm.$route.name === 'authLogin' ||
    _vm.$route.name === 'authRegister' ||
    _vm.$route.name === 'auth0Callback'
  )?_c('div',{attrs:{"id":"app"}},[(_vm.shouldShowVerificationBanner && _vm.bannerVisible)?_c('AccountVerificationBanner',{attrs:{"userEmail":_vm.userEmailForBanner},on:{"dismiss":_vm.temporarilyDismissBanner}}):_vm._e(),_c('PageLayout',{class:{ 'has-verification-banner': _vm.shouldShowVerificationBanner && _vm.bannerVisible },attrs:{"sections":_vm.sections},scopedSlots:_vm._u([{key:"navbar",fn:function(){return [(_vm.isUserLoggedIn)?_c('NewNavbar'):_vm._e()]},proxy:true},{key:"body",fn:function(){return [_c('q-page-container',{staticClass:"app__body"},[_c('router-view')],1)]},proxy:true}],null,false,2911491463)}),(_vm.shouldShowModal)?_c('GlobalModal'):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }