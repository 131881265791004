<template>
  <q-btn
    :disable="disabled"
    flat
    no-caps
    :data-type="type"
    :class="['baseButton', { 'baseButton--disabled': showAsDisabled }]"
    :color="color"
    :loading="loading"
    :percentage="percentage"
    :ripple="ripple"
    :style="custom_style"
    :type="htmlType"
  >
    <BaseTooltip v-if="tooltip" :tooltip="tooltip" />

    <BaseIcon v-if="!iconOnRight && icon" :custom_style="icon_style" :icon="icon" />
    <span v-if="label" :class="['baseButton__label', icon && `baseButton__label--has-margin--${iconOnRight ? 'right' : 'left'}`]">{{ label }}</span>
    <BaseIcon v-if="iconOnRight && icon" :custom_style="icon_style" :icon="icon" />
  </q-btn>
</template>

<script>
import BaseIcon from '@base/BaseIcon.vue';
import BaseTooltip from '@base/BaseTooltip.vue';

/**
 * BaseButton component.
 *
 * @displayName BaseButton
 */
export default {
  name: 'BaseButton',
  components: {
    BaseIcon,
    BaseTooltip
  },
  props: {
    /**
     * Use this prop to pass a custom style for the button
     */
    custom_style: {
      default: () => ({}),
      type: Object
    },
    /**
     * Use this porp to pass a custom color
     */
    color: { default: '', type: String },
    /**
     * Set button as disabled (use html disabled attribute)
     */
    disabled: { default: false, type: Boolean },
    /**
     * Html type (ex.: submit)
     */
    htmlType: { default: '', type: String },
    /**
     * Use this prop to pass an icon.
     */
    icon: { default: '', type: String },
    /**
     * Setting icon size
     */
    icon_style: {
      default: () => ({}),
      type: Object
    },
    /**
     * Set to true if want the icon on the right side of the button
     */
    iconOnRight: { default: false, type: Boolean },
    /**
     * Show button as disabled (don't use html disabled attribute)
     */
    showAsDisabled: { default: false, type: Boolean },
    /**
     * The text to be shown on the button
     */
    label: { default: '', type: String },
    /**
     * Use this prop to show loading spinner if needed
     */
    loading: { default: false, type: Boolean },
    /**
     * Use this prop to pass loading percentage
     */
    percentage: { default: 0, type: Number },
    /**
     * Use this prop to pass ripple
     */
    ripple: { default: false, type: Boolean },
    /**
     * Use this prop to set the button text color
     */
    text_color: { default: '', type: String },
    /**
     * Use this prop to set the button tooltip text
     */
    tooltip: { default: '', type: String },
    /**
     * Use this prop to set the button type (danger, warning, default),
     * this will update its style
     */
    type: { default: '', type: String }
  }
};
</script>

<style lang="scss">
.baseButton {
  border-radius: 8px !important;
  max-height: 48px !important;
  height: 48px !important;
  padding: 0 16px !important;

  &[data-type='danger'] {
    background-color: var(--button-bg-danger);
    color: var(--terziary-text-color);

    .baseIcon {
      stroke: var(--terziary-text-color);
    }
    @include responsive($max: md) {
      background-color: var(--terziary-text-color);
      color: var(--button-bg-danger);
      padding: 0 !important;
      .baseIcon {
        display: none;
      }
    }
  }

  &[data-type='default'] {
    background-color: var(--main-btn-color);
    color: var(--main-text-color);

    .baseIcon {
      stroke: var(--main-text-color);
    }
  }

  &[data-type='dark'] {
    background-color: var(--secondary-btn-color);
    color: var(--terziary-text-color);

    .baseIcon {
      stroke: var(--terziary-text-color);
    }
  }

  &[data-type='info'] {
    background-color: var(--info-bg-color);
    color: var(--terziary-text-color);

    .baseIcon {
      stroke: var(--terziary-text-color);
    }
    @include responsive($max: md) {
      background-color: var(--terziary-text-color);
      color: var(--info-bg-color);
      padding: 0 !important;
      .baseIcon {
        display: none;
      }
    }
  }

  &.disabled,
  &--disabled {
    background-color: var(--terziary-btn-color) !important;
    color: var(--main-text-color);
    * {
      cursor: not-allowed !important;
    }
    .baseIcon {
      stroke: var(--main-text-color) !important;
    }

    opacity: 1 !important;
  }

  &__label {
    font-family: 'Inter', sans-serif;
    font-size: calculateRem(14px);
    font-weight: bold;

    &--has-margin {
      &--left {
        margin-left: 10px;
      }

      &--right {
        margin-right: 10px;
      }
    }
  }

  &__tooltip {
    background-color: red;
  }

  .q-btn__wrapper {
    min-height: unset;
    padding: 0;
  }
}
</style>
