<template>
  <q-header flat class="bg-white text-white navbar" style="border-bottom: 0.5px solid rgba(0, 0, 0, 0.15)">
    <q-toolbar>
      <q-btn flat dense round color="secondary" @click="toggleLeftDrawer" aria-label="Menu" icon="menu" class="navbar__drawerBtn" />

      <q-toolbar-title v-if="$q.screen.gt.sm" shrink class="row items-center no-wrap" @click="handleRoute('home')">
        <img class="navbar__logo" src="@/assets/logo.png" :title="`version: ${version}`" />
        <!-- <img class="baseNavbar__simpleLogo" src="@/assets/svg/logo.svg" /> -->
      </q-toolbar-title>

      <q-space />

      <div class="q-gutter-sm row items-center no-wrap">
        <q-btn round dense flat :color="notificationBtnColor" icon="notifications" class="notification-btn" @mouseover="notificationBtnColor = 'primary'" @mouseleave="notificationBtnColor = 'grey-8'">
          <!-- <q-badge color="red" text-color="white" floating>
              2
            </q-badge> -->
          <!-- <q-tooltip>Notifications</q-tooltip> -->
          <q-menu class="notifications-menu">
            <div class="notifications-header q-px-md q-py-sm">
              <div class="notifications-menu__title">Notifications</div>
            </div>

            <q-separator />

            <div class="q-pa-xs">
              <div class="empty-state q-py-md text-center">
                <q-icon name="notifications_none" size="48px" color="grey-5" />
                <div class="empty-state-text q-mt-md">You're all caught up!</div>
                <div class="empty-state-subtext q-mt-xs">We'll notify you here with updates, tips, and more soon.</div>
              </div>
            </div>
          </q-menu>
        </q-btn>
        <BaseButton icon="plus" v-if="isAdmin" label="New Customer" type="info" @click.native="handleRoute('businessRegistration')" />
        <BookNewShootButton v-if="isAdmin" />
        <UserButton />
      </div>
    </q-toolbar>

    <NavbarDrawer :show_menu.sync="leftDrawerOpen" @drawer-toggle="handleDrawerToggle" />
    <!-- <StickySidebar/> -->
  </q-header>
</template>

<script>
// Components
import NavbarDrawer from '@common/NavbarDrawer.vue';
import { mapGetters } from 'vuex';
import UserButton from '@components/UserButton.vue';
import BookNewShootButton from '@common/BookNewShootButton.vue';
import BaseButton from '@base/BaseButton.vue';

export default {
  name: 'NewNavbar',
  components: {
    NavbarDrawer,
    UserButton,
    BookNewShootButton,
    BaseButton
  },
  data() {
    return {
      version: process.env.VUE_APP_BUILD_NUMBER || 'local',
      leftDrawerOpen: false,
      notificationBtnColor: 'grey-8'
    };
  },
  computed: {
    ...mapGetters({
      isClient: 'user/isClient',
      isAdmin: 'user/isAdmin',
      isEditor: 'user/isEditor'
    })
  },
  methods: {
    toggleLeftDrawer() {
      console.log('this in new navbar: ', this.leftDrawerOpen);
      this.leftDrawerOpen = !this.leftDrawerOpen;
    },

    handleRoute(routeName) {
      this.$router.push({ name: routeName });
    },

    handleDrawerToggle(value) {
      this.leftDrawerOpen = value;
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  padding: 16px 0;

  &__logo {
    cursor: pointer;
    height: 35px;
    width: 150px;
    object-fit: cover;
  }

  &__drawerBtn {
    margin-right: 8px;
    color: var(--secondary);
  }
}

.notification-btn {
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(var(--dark-bg), 0.05);
  }

  &:active,
  &.q-btn--active {
    background-color: rgba(var(--dark-bg), 0.1);
  }
}

.notifications-menu {
  border-radius: 12px;
  min-width: 300px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(151, 151, 151, 0.2);
}

.notifications-header {
  padding: 12px 16px;
}

.notifications-menu__title {
  @include inter-font($size: 16px, $bolded: true, $color: var(--main-text-color));
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.empty-state-text {
  @include inter-font($size: 15px, $bolded: true, $color: var(--main-text-color));
  margin-top: 16px;
}

.empty-state-subtext {
  @include inter-font($size: 13px, $bolded: false, $color: var(--secondary-text-color));
  opacity: 0.8;
  max-width: 90%;
  text-align: center;
  margin-top: 6px;
  line-height: 1.4;
}
</style>
