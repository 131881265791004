import store from '../store';

const GlobalModalPlugin = {
  install(Vue) {
    Vue.prototype.$globalModal = {
      open(options = {}) {
        const { title = 'Complete your profile', data = {}, onSubmit = null } = options;

        store.dispatch('modal/openModal', {
          title,
          data,
          callback: onSubmit
        });
      }
    };
  }
};

export default GlobalModalPlugin;
