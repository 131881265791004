const defaultState = () => {
  return {
    notification: {},
    verificationBannerVisible: true,
    verificationBannerDismissedUntil: null
  };
};

const notifications = {
  namespaced: true,
  state: defaultState(),
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState());
    },
    /**
     * Update status notification
     */
    updateNotification(state, notification) {
      state.notification = notification;
    },
    SET_VERIFICATION_BANNER_VISIBLE(state, visible) {
      state.verificationBannerVisible = visible;
    },
    SET_VERIFICATION_BANNER_DISMISSED_UNTIL(state, timestamp) {
      state.verificationBannerDismissedUntil = timestamp;
    }
  },
  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    /**
     * Add new notification when error occures
     */
    addFailureNotification({ commit }, notification) {
      const _notification = { color: 'negative', textColor: 'white', position: 'top-right', message: notification };
      commit('updateNotification', _notification);
    },
    /**
     * Add new notification
     */
    addSuccessNotification({ commit }, notification) {
      const _notification = { color: 'primary', textColor: 'black', position: 'top-right', message: notification };
      commit('updateNotification', _notification);
    },
    dismissVerificationBanner({ commit }, hours = 2) {
      // Set the banner to be invisible until a specific time
      const dismissedUntil = Date.now() + hours * 60 * 60 * 1000;

      commit('SET_VERIFICATION_BANNER_VISIBLE', false);
      commit('SET_VERIFICATION_BANNER_DISMISSED_UNTIL', dismissedUntil);

      // Store in localStorage for persistence across browser sessions
      localStorage.setItem('verificationBannerDismissedUntil', dismissedUntil);
    },
    resetVerificationBanner({ commit }) {
      commit('SET_VERIFICATION_BANNER_VISIBLE', true);
      commit('SET_VERIFICATION_BANNER_DISMISSED_UNTIL', null);

      localStorage.removeItem('verificationBannerDismissedUntil');
    },
    initializeNotificationState({ commit }) {
      // Restore state from localStorage if exists
      const dismissedUntil = localStorage.getItem('verificationBannerDismissedUntil');

      if (dismissedUntil) {
        const timestamp = parseInt(dismissedUntil, 10);

        // Only restore if the dismissal period hasn't passed
        if (Date.now() < timestamp) {
          commit('SET_VERIFICATION_BANNER_VISIBLE', false);
          commit('SET_VERIFICATION_BANNER_DISMISSED_UNTIL', timestamp);
        } else {
          // Clear the stored value if the period has passed
          localStorage.removeItem('verificationBannerDismissedUntil');
        }
      }
    }
  },
  getters: {
    /**
     * Retrieving errors
     */
    getNotification: state => state.notification,
    shouldShowVerificationBanner: state => {
      if (!state.verificationBannerVisible) return false;

      // If there's a dismissal timestamp, check if it's passed
      if (state.verificationBannerDismissedUntil) {
        return Date.now() > state.verificationBannerDismissedUntil;
      }

      return true;
    }
  }
};

export default notifications;
