const state = {
  isOpen: false,
  title: 'Complete your profile',
  modalData: null,
  callback: null
};

const getters = {
  isModalOpen: state => state.isOpen,
  getModalTitle: state => state.title,
  getModalData: state => state.modalData
};

const mutations = {
  OPEN_MODAL(state, { title = 'Complete your profile', data = null, callback = null }) {
    state.isOpen = true;
    state.title = title;
    state.modalData = data;
    state.callback = callback;
  },
  CLOSE_MODAL(state) {
    state.isOpen = false;
  }
};

const actions = {
  openModal({ commit }, { title, data, callback } = {}) {
    commit('OPEN_MODAL', { title, data, callback });
  },
  handleSubmit({ state }, formData) {
    if (typeof state.callback === 'function') {
      state.callback(formData);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
