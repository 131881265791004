<template>
  <transition name="banner-slide">
    <div class="verification-banner">
      <div class="verification-banner__content">
        <q-icon name="info" size="24px" class="verification-banner__icon" />
        <span class="verification-banner__text"> Please verify your email address{{ displayEmail }}. </span>

        <!-- Status message -->
        <div v-if="showStatusMessage" :class="['verification-banner__status', statusClass]">
          <q-icon :name="statusIcon" size="18px" class="q-mr-xs text-white" />
          <span class="text-white">{{ statusMessage }}</span>
        </div>

        <!-- Resend button -->
        <q-btn v-else outline no-caps color="secondary" class="verification-banner__btn" :loading="isResending" :disable="isResending" @click="resendEmail"> Resend email </q-btn>
      </div>
      <q-btn flat round dense icon="close" class="verification-banner__close" @click="dismiss" />
    </div>
  </transition>
</template>

<script>
import { ref, computed } from '@vue/composition-api';
import { AuthApi } from '@/api/modules/auth';

export default {
  name: 'AccountVerificationBanner',

  props: {
    userEmail: {
      type: String,
      default: ''
    }
  },

  setup(props, { emit }) {
    // State
    const isResending = ref(false);
    const showStatusMessage = ref(false);
    const isSuccess = ref(true);
    const statusTimer = ref(null);

    // Computed properties
    const displayEmail = computed(() => (props.userEmail ? ` (${props.userEmail})` : ''));
    const statusClass = computed(() => (isSuccess.value ? 'bg-positive' : 'bg-negative'));
    const statusIcon = computed(() => (isSuccess.value ? 'check_circle' : 'error'));
    const statusMessage = computed(() => (isSuccess.value ? 'Email sent successfully' : 'Failed to send email'));

    // Methods
    const dismiss = () => emit('dismiss');

    const showStatus = success => {
      isSuccess.value = success;
      showStatusMessage.value = true;

      if (statusTimer.value) clearTimeout(statusTimer.value);

      statusTimer.value = setTimeout(() => {
        showStatusMessage.value = false;
      }, 10000);
    };

    const resendEmail = async () => {
      if (isResending.value) return;

      isResending.value = true;

      try {
        await AuthApi.verifyEmail();
        showStatus(true);
      } catch (error) {
        showStatus(false);
      } finally {
        isResending.value = false;
      }
    };

    // Cleanup
    const clearTimer = () => {
      if (statusTimer.value) clearTimeout(statusTimer.value);
    };

    return {
      isResending,
      showStatusMessage,
      displayEmail,
      statusClass,
      statusIcon,
      statusMessage,
      dismiss,
      resendEmail,
      clearTimer
    };
  },

  beforeDestroy() {
    this.clearTimer();
  }
};
</script>

<style lang="scss" scoped>
.verification-banner {
  width: 100%;
  background-color: var(--main-btn-color);
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);

  &__content {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 16px;
    min-height: 40px;
  }

  &__icon {
    color: var(--main-text-color);
    opacity: 0.8;
  }

  &__text {
    @include inter-font($size: 16px, $color: var(--main-text-color));
    flex: 1;
    text-align: left;
    margin-right: 24px;
    line-height: 1.4;

    @media (max-width: 768px) {
      font-size: 14px;
    }
  }

  &__btn {
    margin-left: auto;
    height: 38px;
  }

  &__status {
    @include inter-font($size: 14px, $bolded: true);
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 8px 14px;
    margin-left: auto;
    animation: fadeInSlide 0.3s ease-in-out;

    &.bg-positive {
      background-color: var(--positive);
    }

    &.bg-negative {
      background-color: var(--negative);
    }
  }

  &__close {
    color: var(--main-text-color);
    opacity: 0.7;
    margin-left: 12px;

    &:hover {
      opacity: 1;
      background-color: rgba(0, 0, 0, 0.08);
    }
  }

  @media (max-width: 480px) {
    padding: 10px 16px;

    &__content {
      flex-wrap: wrap;
      gap: 10px;
    }

    &__text {
      flex: 0 0 100%;
      margin-right: 0;
      margin-bottom: 6px;
    }

    &__btn,
    &__status {
      margin-left: 0;
    }
  }
}

@keyframes fadeInSlide {
  0% {
    opacity: 0;
    transform: translateX(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.banner-slide-enter-active,
.banner-slide-leave-active {
  transition: all 0.3s ease;
}

.banner-slide-enter,
.banner-slide-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
</style>
