/**
 * Customer.io integration service using traditional tracking snippet
 * Context: 2025-02-27 09:29:58, User: demon12c1
 */
class CustomerIOService {
  constructor() {
    this.initialized = false;
    this._cio = null;
    this.userContext = {
      currentDate: new Date().toISOString(),
      userLogin: 'N/A'
    };
    this.loadStartTime = null;
    this.loadTimeout = null;
    this.scriptLoaded = false;
  }

  /**
   * Initialize Customer.io with the traditional tracking snippet
   * @param {string} siteId - Your Customer.io site ID
   */
  init(siteId) {
    if (this.initialized) return;

    if (!siteId) {
      console.error('[CustomerIO] Site ID is required');
      return;
    }

    try {
      // Start tracking load time
      this.loadStartTime = Date.now();

      // Initialize the global _cio object
      window._cio = window._cio || [];

      // Create function for each method
      const methodCreator = function (methodName) {
        return function () {
          window._cio.push([methodName].concat(Array.prototype.slice.call(arguments, 0)));
        };
      };

      // Define available methods
      const methods = ['load', 'identify', 'sidentify', 'track', 'page', 'on', 'off'];

      // Create methods on _cio
      for (let i = 0; i < methods.length; i++) {
        window._cio[methods[i]] = methodCreator(methods[i]);
      }

      // Create script tag
      const script = document.createElement('script');
      script.async = true;
      script.id = 'cio-tracker';
      script.setAttribute('data-site-id', siteId);
      script.setAttribute('data-use-array-params', 'true');
      script.setAttribute('data-use-in-app', 'true');

      // Add onload/onerror handlers for verification
      script.onload = () => {
        this.scriptLoaded = true;
        clearTimeout(this.loadTimeout);
        console.log(`[CustomerIO] Script loaded successfully in ${Date.now() - this.loadStartTime}ms`);
      };

      script.onerror = () => {
        console.error('[CustomerIO] Failed to load script');
        clearTimeout(this.loadTimeout);
      };

      // Set source URL
      script.src = 'https://assets.customer.io/assets/track-eu.js';

      // Add script to DOM
      const firstScript = document.getElementsByTagName('script')[0];
      firstScript.parentNode.insertBefore(script, firstScript);

      // Reference to _cio
      this._cio = window._cio;

      // Setup timeout to verify script loading
      this.loadTimeout = setTimeout(() => {
        if (!this.scriptLoaded) {
          console.error('[CustomerIO] Script loading timed out after 10 seconds');
          this._verifyIntegration();
        }
      }, 10000);

      // Signal that initialization attempt completed
      this.initialized = true;
      console.log('[CustomerIO] Initialization started with Site ID:', siteId);
      console.log('[CustomerIO] Current context:', this.userContext);

      // Run verification after a short delay
      setTimeout(() => {
        this._verifyIntegration();
      }, 3000);
    } catch (error) {
      console.error('[CustomerIO] Failed to initialize:', error);
    }
  }

  /**
   * Verify that the Customer.io integration loaded successfully
   * @private
   */
  _verifyIntegration() {
    console.log('[CustomerIO] Verifying integration...');

    // Check 1: Verify the script was loaded
    const scriptElement = document.getElementById('cio-tracker');
    const scriptLoaded = !!scriptElement;
    console.log('[CustomerIO] Script element found in DOM:', scriptLoaded);

    // Check 2: Verify the global object exists with expected methods
    const globalExists = typeof window._cio !== 'undefined';
    console.log('[CustomerIO] Global _cio object exists:', globalExists);

    if (globalExists) {
      const methods = ['identify', 'track', 'page', 'on', 'off'];
      const implementedMethods = methods.filter(method => typeof window._cio[method] === 'function');
      console.log(`[CustomerIO] Found ${implementedMethods.length}/${methods.length} expected methods:`, implementedMethods);
    }

    // Check 3: Verify data attributes
    try {
      const trackerScript = document.getElementById('cio-tracker');
      if (trackerScript) {
        const siteId = trackerScript.getAttribute('data-site-id');
        const useInApp = trackerScript.getAttribute('data-use-in-app');
        console.log('[CustomerIO] Script attributes:', {
          'data-site-id': siteId,
          'data-use-in-app': useInApp
        });
      }
    } catch (e) {
      console.warn('[CustomerIO] Could not verify script attributes:', e);
    }

    // Overall verification result
    const isLoaded = scriptLoaded && globalExists;
    console.log('[CustomerIO] Integration verification result:', isLoaded ? 'SUCCESS' : 'FAILED');

    return isLoaded;
  }

  /**
   * Identify a user
   * @param {Object} traits - User traits/attributes
   */
  identify(traits = {}) {
    if (!this.initialized || !this._cio) {
      console.error('[CustomerIO] Not initialized');
      return;
    }
    const userId = traits.id;

    if (!userId) {
      console.error('[CustomerIO] User ID is required for identify');
      return;
    }

    try {
      // First verify the SDK is loaded
      const isLoaded = this._verifyIntegration();
      if (!isLoaded) {
        console.warn('[CustomerIO] Attempting to identify user but SDK might not be fully loaded');
      }

      // Add created_at if not provided (required by Customer.io)
      if (!traits.created_at) {
        traits.created_at = Math.floor(new Date(this.userContext.currentDate).getTime() / 1000);
      }

      // Identify the user to Customer.io
      this._cio.identify(traits);
      console.log(`[CustomerIO] User identified: ${userId}`, traits);

      // Update internal user context
      this.userContext.userLogin = userId;
    } catch (error) {
      console.error('[CustomerIO] Failed to identify user:', error);
    }
  }

  /**
   * Track an event
   * @param {string} event - The event name
   * @param {Object} properties - Event properties
   */
  track(event, properties = {}) {
    if (!this.initialized || !this._cio) {
      console.error('[CustomerIO] Not initialized');
      return;
    }

    if (!event) {
      console.error('[CustomerIO] Event name is required for tracking');
      return;
    }

    try {
      // Verify the SDK is loaded
      const isLoaded = this._verifyIntegration();
      if (!isLoaded) {
        console.warn('[CustomerIO] Attempting to track event but SDK might not be fully loaded');
      }

      // Track the event
      this._cio.track(event, properties);
      console.log(`[CustomerIO] Event tracked: ${event}`, properties);
    } catch (error) {
      console.error('[CustomerIO] Failed to track event:', error);
    }
  }

  /**
   * Track a page view
   * @param {string} name - The page name
   * @param {Object} properties - Page properties
   */
  page(name, properties = {}) {
    if (!this.initialized || !this._cio) {
      console.error('[CustomerIO] Not initialized');
      return;
    }

    try {
      // Verify the SDK is loaded
      const isLoaded = this._verifyIntegration();
      if (!isLoaded) {
        console.warn('[CustomerIO] Attempting to track page but SDK might not be fully loaded');
      }

      this._cio.page(name, properties);
      console.log(`[CustomerIO] Page tracked: ${name || 'unnamed'}`, properties);
    } catch (error) {
      console.error('[CustomerIO] Failed to track page:', error);
    }
  }

  /**
   * Reset the identified user
   */
  reset() {
    if (!this.initialized || !this._cio) {
      return;
    }

    try {
      if (typeof this._cio.reset === 'function') {
        this._cio.reset();
        console.log('[CustomerIO] User reset');
      } else {
        console.warn('[CustomerIO] Reset method not available');
      }
    } catch (error) {
      console.error('[CustomerIO] Failed to reset user:', error);
    }
  }
}

// Create and export a singleton instance
const customerIO = new CustomerIOService();

export default customerIO;
