import store from '@/store/index';

export default [
  {
    path: '/',
    name: 'home',
    beforeEnter: (to, from, next) => {
      // Editor not have the homepages
      const isEditor = store.getters['user/isEditor'];
      if (!isEditor) {
        next();
        return;
      }

      next({
        name: isEditor ? 'orders' : 'home'
      });
    },
    component: () => import(/* webpackChunkName: "home" */ '@views/HomePage.vue')
  },
  {
    path: '/orders',
    name: 'orders',
    query: { view: 'list' || 'grid' },
    component: () => import(/* webpackChunkName: "home" */ '@views/Orders.vue')
  }
];
