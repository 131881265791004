<template>
  <q-dialog v-model="isOpen" persistent :maximized="$q.screen.lt.sm" transition-show="scale" transition-hide="scale">
    <q-card class="global-modal">
      <q-card-section class="row items-center">
        <div class="column q-gutter-y-sm">
          <div class="text-h6">{{ title }}</div>
          <div class="text-subtitle2 text-grey">Tell us about yourself to get started.</div>
        </div>
        <q-space />
        <q-btn icon="close" flat round dense v-close-popup @click="onClose" />
      </q-card-section>

      <q-card-section>
        <div class="q-mb-sm">
          <div class="text-subtitle1">Company Name <span class="text-negative">*</span></div>
        </div>
        <q-input v-model="companyName" required outlined label="e.g Flashy technologies" hide-bottom-space :rules="[val => !!val || 'Company name is required']" ref="companyNameInput" />
      </q-card-section>

      <q-card-actions align="right" class="q-px-md q-pb-md">
        <BaseButton class="full-width" type="default" :loading="isSubmitting" :disabled="isSubmitting" @click.native="onSubmit" label="Complete" style="color: black" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import BaseButton from '@base/BaseButton.vue';

export default {
  name: 'GlobalModal',
  components: { BaseButton },
  data() {
    return {
      companyName: '',
      isSubmitting: false
    };
  },
  computed: {
    ...mapGetters({
      isOpen: 'modal/isModalOpen',
      title: 'modal/getModalTitle',
      initialData: 'modal/getModalData'
    })
  },
  watch: {
    isOpen(newVal) {
      if (newVal && this.initialData && this.initialData.companyName) {
        this.companyName = this.initialData.companyName;
      } else if (newVal) {
        this.companyName = '';
      }

      // Focus the input when modal opens
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.companyNameInput && this.$refs.companyNameInput.focus();
        });
      }
    }
  },
  methods: {
    ...mapMutations({
      closeModal: 'modal/CLOSE_MODAL'
    }),
    onClose() {
      this.closeModal();
    },
    async onSubmit() {
      if (!this.companyName.trim()) {
        this.$q.notify({
          color: 'negative',
          message: 'Company name is required'
        });
        return;
      }

      this.isSubmitting = true;

      try {
        await this.$store.dispatch('modal/handleSubmit', {
          companyName: this.companyName
        });

        // Show success notification
        this.$q.notify({
          color: 'positive',
          message: 'Company name saved successfully'
        });

        this.closeModal();
      } catch (error) {
        console.error('Error saving company name:', error);
        this.$q.notify({
          color: 'negative',
          message: 'Failed to save company name. Please try again.'
        });
      } finally {
        this.isSubmitting = false;
      }
    }
  }
};
</script>

<style lang="scss">
.global-modal {
  min-width: 400px;

  @media (max-width: 599px) {
    width: 100%;
  }
}
</style>
