<template>
  <!-- Main navigation drawer component -->
  <q-drawer
    show-if-above
    v-model="showMenuLocal"
    no-swipe-backdrop
    no-swipe-close
    no-swipe-open
    behavior="mobile"
    side="left"
    bordered
    content-class="bg-grey-1"
    :width="300"
    @click="leftDrawerOpen"
    @input="onDrawerToggle"
  >
    <!-- Scrollable area for menu items -->
    <q-scroll-area class="fit">
      <div class="drawer-header">
        <q-btn flat dense round color="secondary" @click="closeDrawer" aria-label="Menu" icon="menu" class="navbar__drawerBtn" />
        <img class="drawer-logo" src="@/assets/logo.png" />
      </div>
      <q-list padding class="navDrawer__list" align="left">
        <template v-if="isClient || isB2C || isSubClient">
          <q-item class="navDrawer__drawer-item" clickable v-ripple :active="isActive('home')" @click.native="handleRoute('home')">
            <div class="navDrawer__content">
              <q-item-section avatar class="navDrawer__icon-container">
                <q-icon color="grey-7" name="o_space_dashboard" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-secondary" style="font-size: 16px">{{ $t('Dashboard') }}</q-item-label>
              </q-item-section>
            </div>
          </q-item>
        </template>

        <!-- Orders/Shoots menu item - shown for all users -->
        <q-item class="navDrawer__drawer-item" clickable :active="isActive('orders')" @click.native="handleRoute('orders')">
          <div class="navDrawer__content">
            <q-item-section avatar class="navDrawer__icon-container">
              <q-icon color="grey-7" name="o_photo_camera" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-secondary" style="font-size: 16px">{{ getLabel }}</q-item-label>
            </q-item-section>
          </div>
        </q-item>

        <!-- Gallery menu item - shown for clients and admins -->
        <template v-if="isClient || isAdmin || isSubClient || isB2C">
          <q-item class="navDrawer__drawer-item" clickable :active="isActive('gallery')" @click.native="handleRoute('gallery')">
            <div class="navDrawer__content">
              <q-item-section avatar class="navDrawer__icon-container">
                <q-icon color="grey-7" name="o_image" :style="icon_style" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-secondary" style="font-size: 16px">{{ $t('Gallery') }}</q-item-label>
              </q-item-section>
            </div>
          </q-item>
        </template>

        <!-- Admin-only menu items -->
        <template v-if="isAdmin">
          <!-- Packages menu item -->
          <q-item class="navDrawer__drawer-item" clickable :active="isActive('packages')" @click.native="handleRoute('packages')">
            <div class="navDrawer__content">
              <q-item-section avatar class="navDrawer__icon-container">
                <q-icon color="grey-7" name="inventory_2" :style="icon_style" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-secondary" style="font-size: 16px">{{ $t('Packages') }}</q-item-label>
              </q-item-section>
            </div>
          </q-item>

          <!-- Accounts menu item -->
          <q-item class="navDrawer__drawer-item" clickable :active="isActive('accounts')" @click.native="handleRoute('accounts')">
            <div class="navDrawer__content">
              <q-item-section avatar class="navDrawer__icon-container">
                <q-icon color="grey-7" name="o_manage_accounts" :style="icon_style" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-secondary" style="font-size: 16px">{{ $t('Accounts') }}</q-item-label>
              </q-item-section>
            </div>
          </q-item>

          <!-- Reports menu item -->
          <q-item class="navDrawer__drawer-item" clickable :active="isActive('reports')" @click.native="handleRoute('reports')">
            <div class="navDrawer__content">
              <q-item-section avatar class="navDrawer__icon-container">
                <q-icon color="grey-7" name="o_assessment" :style="icon_style" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-secondary" style="font-size: 16px">{{ $t('Reports') }}</q-item-label>
              </q-item-section>
            </div>
          </q-item>
        </template>

        <!-- Integrations menu item -->
        <template v-if="isClient || isB2C || isSubClient">
          <q-item class="navDrawer__drawer-item" clickable :active="isActive('integrations')" @click.native="handleRoute('integrations')">
            <div class="navDrawer__content">
              <q-item-section avatar class="navDrawer__icon-container">
                <q-icon color="grey-7" name="link" :style="icon_style" />
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-secondary" style="font-size: 16px">{{ $t('Integrations') }}</q-item-label>
              </q-item-section>
            </div>
          </q-item>
        </template>
      </q-list>
    </q-scroll-area>
  </q-drawer>
</template>

<script>
// Vuex state management
import { mapGetters } from 'vuex';

// Utility mixins
import checkBreakpoint from '@utils/mixins/check-breakpoint.js';

export default {
  name: 'NavbarDrawer',
  mixins: [checkBreakpoint],
  // Component props
  props: {
    show_menu: {
      type: Boolean,
      default: false
    }
  },

  // Component data
  data() {
    return {
      version: process.env.VUE_APP_BUILD_NUMBER || 'local',
      // Icon styling
      icon_style: {
        fontSize: '25px'
      },
      showMenuLocal: this.show_menu,
      leftDrawerOpen: false,
      isNavigating: false // Flag to prevent multiple rapid clicks
    };
  },

  // Computed properties
  computed: {
    // Map Vuex getters for user roles
    ...mapGetters({
      isAdmin: 'user/isAdmin',
      isPhotographer: 'user/isPhotographer',
      isClient: 'user/isClient',
      isEditor: 'user/isEditor',
      isSubClient: 'user/isSubClient',
      isB2C: 'user/isB2C'
    }),

    /**
     * Get appropriate label for orders/shoots menu item based on user role
     * @returns {string} Translated label text
     */
    getLabel() {
      if (this.isAdmin) return this.$t('ORDERS');
      return this.$t('SHOOTS');
    }
  },

  // Watch for prop changes
  watch: {
    show_menu(newVal) {
      this.showMenuLocal = newVal;
    }
  },

  // Lifecycle hooks
  mounted() {
    window.addEventListener('resize', this.handleWindowSize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowSize);
  },

  // Component methods
  methods: {
    /**
     * Handle navigation to different routes
     * @param {string} type Route name to navigate to
     */
    handleRoute(type) {
      // Prevent multiple rapid clicks from causing navigation issues
      if (this.isNavigating) return;

      // Set navigating flag to true to prevent additional clicks
      this.isNavigating = true;

      // Check if we're already on this route to avoid redundant navigation
      if (this.$route.name === type) {
        // If already on the route, just reset the flag after a short delay
        setTimeout(() => {
          this.isNavigating = false;
        }, 300);
        return;
      }

      // Navigate to the new route with replace to avoid browser history stacking
      this.$router
        .replace({ name: type })
        .catch(err => {
          // Only log errors that aren't navigation duplications
          if (err.name !== 'NavigationDuplicated') {
            console.error('Navigation error:', err);
          }
        })
        .finally(() => {
          // Reset the navigation flag after a short delay
          setTimeout(() => {
            this.isNavigating = false;
          }, 300);
        });
    },

    /**
     * Check if given route name matches current route
     * @param {string} routeName Route name to check
     * @returns {boolean} Whether route is active
     */
    isActive(routeName) {
      return this.$route.name === routeName;
    },

    onDrawerToggle(value) {
      this.showMenuLocal = value;
      this.$emit('drawer-toggle', value);
      if (!value) {
        this.leftDrawerOpen = false;
        console.log('Drawer closed');
      } else {
        console.log('Drawer opened');
      }
    },

    closeDrawer() {
      this.showMenuLocal = false;
      this.$emit('drawer-toggle', false);
    }
  }
};
</script>

<style lang="scss">
/* Navigation drawer styling */
.navDrawer {
  &__list {
    padding: 16px 0;
  }

  &__drawer-item {
    line-height: 1.5rem;
    border-radius: 0 1.5rem 1.5rem 0;
    margin: 8px 16px;
    padding: 12px 24px;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  &__content {
    display: flex;
    align-items: center;
    width: 100%;

    .q-item-section {
      display: flex;
      align-items: center;
      justify-content: center;

      .q-item-label {
        @include inter-font(18px, true);
        color: var(--main-text-color);
      }
    }
  }

  &__drawer-item:hover {
    background-color: rgba(254, 213, 89, 0.1);
  }

  &__drawer-item.q-item--active {
    background-color: rgba(254, 213, 89, 0.1);
    @include inter-font(16px, true);
    border-left: 4px solid #fed559;
    padding-left: 20px; // Compensate for border

    .q-icon,
    .q-item-label {
      color: var(--main-text-color) !important;
    }
  }

  .q-item-label:not(.q-item__label--caption) {
    @include mona-sans-font(14px, true);
    color: var(--main-text-color);
    letter-spacing: 0.018em;
    line-height: 1.25rem;
    white-space: nowrap;
  }

  &__icon-container {
    width: 32px !important;
    min-width: 32px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }
}

.drawer-header {
  display: flex;
  align-items: left;
  margin: 16px 0;
  padding: 12px;
}

.drawer-logo {
  height: 45px;
  margin-left: 24px;
}
</style>
