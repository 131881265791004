import { render, staticRenderFns } from "./NewNavbar.vue?vue&type=template&id=14f4f583&scoped=true&"
import script from "./NewNavbar.vue?vue&type=script&lang=js&"
export * from "./NewNavbar.vue?vue&type=script&lang=js&"
import style0 from "./NewNavbar.vue?vue&type=style&index=0&id=14f4f583&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14f4f583",
  null
  
)

export default component.exports
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QHeader,QToolbar,QBtn,QToolbarTitle,QSpace,QBadge,QTooltip,QMenu,QSeparator,QIcon});
