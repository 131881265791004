// src/services/auth.service.js
import instance from '../index';
import { setCookie } from '@utils/cookies';
import jwt_decode from 'jwt-decode';

const AUTH0_TOKEN = 'token';
const RES_BUSINESS = 'client';
const RESOURCE_SIGNUP = 'signup';
// const RES_USER = 'user';

const VERIFY_ACCOUNT = 'verify-account';

class AuthService {
  async login(email, password) {
    try {
      const response = await instance.post(`${AUTH0_TOKEN}`, { username: email, password, grant_type: 'password' });
      const decodedToken = jwt_decode(response?.data?.jwt);
      const expiration = decodedToken.exp;
      setCookie({ cookieName: 'jwt', cookieValue: response?.data?.jwt, expiration });
    } catch (error) {
      console.error('Authentication error:', error);
      throw error;
    }
  }

  async verifyAccount() {
    try {
      const response = await instance.get(`${VERIFY_ACCOUNT}`);
      return response.data;
    } catch (error) {
      console.error('Verification error:', error);
      throw error;
    }
  }

  /**
   * Parse verification parameters from URL
   * @returns {Object} - Verification parameters
   */
  getVerificationParamsFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    return {
      code: urlParams.get('code'),
      success: urlParams.get('success') === 'true',
      message: urlParams.get('message'),
      verificationCode: urlParams.get('verification')
    };
  }

  /**
   * Clean the URL by removing query parameters
   */
  cleanUrl() {
    setTimeout(() => {
      const cleanUrl = window.location.pathname;
      window.history.replaceState({}, document.title, cleanUrl);
    }, 100);
  }

  logout() {
    // Clear access token and user info from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('user_info');
  }

  prepareProfileInformation({ role, firstName, lastName, email, phone, password, ...additionalInformations }) {
    const profile = {
      additionalInformations: {},
      user: {},
      paymentDetail: {
        type: 'test',
        details: {}
      }
    };

    if (firstName) {
      profile.user.name = `${firstName} ${lastName}`.trim();
    }

    if (password) {
      profile.password = password;
    }

    if (email) {
      profile.user.email = email;
    }

    if (phone) {
      profile.user.phone = phone;
    }

    if (additionalInformations) {
      profile.additionalInformations = additionalInformations;
    }

    if (role) {
      profile.role = role;
    }

    return profile;
  }

  async resetPassword(email) {
    try {
      const response = await instance.post('/reset-password', { email });
      return response.data;
    } catch (error) {
      console.error('Reset password error:', error);
      throw error;
    }
  }

  async register(signupData) {
    try {
      const response = await instance.post(`/${RESOURCE_SIGNUP}/${RES_BUSINESS}`, signupData);
      return response;
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  }

  /**
   * Get user's current location using browser's Geolocation API
   * @returns {Promise} Promise that resolves to location data
   */
  getUserLocation() {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error('Geolocation is not supported by this browser.'));
        return;
      }

      navigator.geolocation.getCurrentPosition(
        async position => {
          try {
            // Get coordinates
            const { latitude, longitude } = position.coords;

            // Use reverse geocoding to get location details
            const response = await fetch(`https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${process.env.VUE_APP_OPENCAGEDATA_API_KEY}`);

            const data = await response.json();
            if (data.results && data.results.length > 0) {
              const locationData = {
                country: data.results[0].components.country,
                countryCode: data.results[0].components.country_code,
                city: data.results[0].components.city || data.results[0].components.town,
                formattedAddress: data.results[0].formatted
              };
              resolve(locationData);
            } else {
              reject(new Error('No location data found'));
            }
          } catch (error) {
            reject(error);
          }
        },
        error => {
          reject(error);
        },
        {
          enableHighAccuracy: false
        }
      );
    });
  }

  async verifyEmail() {
    try {
      const response = await instance.get('/verification/sent');
      return response;
    } catch (error) {
      console.error('Verification error:', error);
      throw error;
    }
  }
}

export const AuthApi = new AuthService();
