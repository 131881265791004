import Vue from 'vue';
import VueMeta from 'vue-meta';
import App from './App.vue';
import VueCompositionApi from '@vue/composition-api';
import { VueQueryPlugin, QueryClient } from '@tanstack/vue-query';

// Store
import store from '@/store';
// Navigation
import router from '@/router';
// Localization
import i18n from './i18n';
// Styles
import '@/styles/main.scss';
// Ui library
import './quasar';
// Filters
import filters from './utils/filters';
// Global mixin
import helpers from './utils/globalMixins';
// Vuelidate
import Vuelidate from 'vuelidate';
//Numeric input
import VueNumericInput from 'vue-numeric-input';
import 'vue-tel-input/dist/css/sprite.css'; // Flags styles
import 'vue-tel-input/dist/css/component.css'; // Component styles
// Vue apex
import VueApexCharts from 'vue-apexcharts';
// Vue Gtag
import VueGtag from 'vue-gtag'; // Import vue-gtag
import GlobalModalPlugin from './plugins/globalModal';

Vue.use(GlobalModalPlugin);
Vue.use(VueCompositionApi);

// Initialize QueryClient
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000 // Example: 5 minutes
    }
  }
});

// Use VueQueryPlugin with the queryClient
Vue.use(VueQueryPlugin, {
  queryClient
});

Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

// V-calendar
import VCalendar, { setupCalendar } from 'v-calendar';
setupCalendar({
  componentPrefix: 'vc'
});

import './quasar';
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: 'vc' // Use <vc-calendar /> instead of <v-calendar />
});

Vue.use(VueNumericInput);
Vue.config.productionTip = false;

//vue-meta
Vue.use(VueMeta);
Vue.mixin(helpers);
Vue.use(Vuelidate);

import OtpInput from '@bachdgvn/vue-otp-input';
Vue.component('v-otp-input', OtpInput);

import VueSnip from 'vue-snip';
Vue.use(VueSnip);

import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload);

import CustomerIOPlugin from './plugins/customerio';
// Register the Customer.io plugin with your credentials

Vue.use(CustomerIOPlugin, {
  siteId: process.env.VUE_APP_CUSTOMER_IO_SITE_ID
});

// Integrate Vue Gtag
Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GA_MEASUREMENT_ID,
      params: {
        send_page_view: true
      }
    },
    customResourceURL: 'https://www.googletagmanager.com/gtag/js',
    customPreconnectOrigin: 'https://www.googletagmanager.com',
    debug: process.env.NODE_ENV !== 'production' // Enable debug mode in non-production environments
  },
  router
);

new Vue({
  filters,
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
