export default [
  {
    path: '/auth/callback',
    name: 'auth0Callback',
    component: () => import('@/views/Auth0Callback.vue')
  },
  {
    path: '/auth/login',
    name: 'authLogin',
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue')
  },
  {
    path: '/auth/register',
    name: 'authRegister',
    component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue')
  }
];
